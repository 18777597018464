import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import * as Sentry from '@sentry/react'

const httpLink = createUploadLink({
  uri: '/graphql', // or your GraphQL endpoint
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      Sentry.captureMessage(`Graphql error ${message}`, {
        extra: {
          operationName: operation.operationName,
          variables: operation.variables,
          path,
        },
      })
    })
  }

  if (networkError) {
    Sentry.captureMessage(`Network error ${networkError}`, {
      extra: {
        operationName: operation.operationName,
        variables: operation.variables,
      },
    })
  }
})

const link = from([errorLink, httpLink])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

export default client
