import React, { useEffect } from 'react'
import { Bars2Icon, FireIcon, PlusIcon, RocketLaunchIcon } from '@heroicons/react/20/solid'
import { clsx } from 'clsx'
import Spin from '../common/ui/Spin'
import { LookerEmbedSDK } from '@looker/embed-sdk'
import './style.scss'

const App = () => {
  const tabs = [
    { name: 'Explore Assistant', id: 'demo::explore_assistant', type: 'app' },
    { name: 'Dashboard Summarization', id: '96', type: 'dashboard' },
    { name: 'Looker Extension GenAI', id: 'demo::looker-genai', type: 'app' },
  ]
  const ref = React.useRef(null)
  const [currentTab, setCurrentTab] = React.useState(0)

  LookerEmbedSDK.init('ddu.cloud.looker.com', '/looker/auth')

  useEffect(() => {
    if (!ref.current) {
      return
    }

    let embed = null
    const embedType = tabs[currentTab].type
    const embedId = tabs[currentTab].id
    if (embedType == 'dashboard') {
      embed = LookerEmbedSDK.createDashboardWithId(embedId)
    } else if (embedType == 'app') {
      embed = LookerEmbedSDK.createExtensionWithId(embedId)
    } else if (embedType == 'explore') {
      embed = LookerEmbedSDK.createExploreWithId(embedId)
    }

    if (!embed) {
      return
    }

    // truncate the iframe container
    ref.current.innerHTML = ''

    embed
      .withDynamicIFrameHeight()
      .appendTo(ref.current)
      .on('dashboard:run:start', () => console.log('start'))
      .on('dashboard:run:complete', () => console.log('done'))
      .build()
      .connect()
      .catch((error: Error) => {
        console.error('An unexpected error occurred', error)
      })
  }, [ref.current, currentTab])

  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <h1 className="flex flex-row items-center space-x-2 text-4xl font-bold text-gray-900">
          <span className="">Looker</span>
          <PlusIcon className="size-8 text-blue-500" />
          <span className="">Gen AI</span>
          <Bars2Icon className="size-8 text-blue-500" />
          <FireIcon className="size-16 text-orange-500" />
          <RocketLaunchIcon className="size-16 text-red-500" />
        </h1>

        <div className="mt-4">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-dd-blue-500 focus:outline-none focus:ring-dd-blue-500 sm:text-sm"
              defaultValue={tabs[currentTab].name}
              onChange={(e) => {
                const index = tabs.findIndex((tab) => tab.name === e.target.value)
                setCurrentTab(index)
              }}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href="#"
                    onClick={() => setCurrentTab(index)}
                    className={clsx(
                      currentTab == index
                        ? 'border-dd-blue-500 text-dd-blue-600'
                        : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                      'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                    )}
                    aria-current={currentTab == index ? 'page' : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-screen w-full">
        <div className="w-full iframe-container" ref={ref} />
        <div className="mt-10">
          <Spin />
        </div>
      </div>
    </div>
  )
}
export default App
